import React, { FC } from 'react';
import { Typography } from '@design-system/typography';

import { Container } from 'layout';

import { IHelpAndSupportProps } from './models';

import './HelpAndSupportBanner.scss';

const HelpAndSupportBanner: FC<IHelpAndSupportProps> = ({
  data: { title, info_block, link, form_title },
}) => (
  <div data-testid="HelpAndSupportBanner">
    <Container className="help-and-support-banner">
      <div className="help-and-support-banner--left">
        <div className="left-part__title">
          <Typography content={title.content} variant="bodyXL" />
        </div>
        {info_block.map(({ title: contentTitle, description }) => (
          <div key={contentTitle.content} className="left-part__content">
            <div className="left-part__content__title">
              <Typography content={contentTitle.content} variant="bodyL" />
            </div>
            <div className="left-part__content__description">
              <Typography
                className="content-typography"
                content={description.content}
                variant="bodyL"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="help-and-support-banner--right">
        <iframe className="help-and-support-banner__form" src={link} title={form_title} />
      </div>
    </Container>
  </div>
);

export default HelpAndSupportBanner;
