import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout/Layout';
import HelpAndSupportBanner from 'components/HelpAndSupportBanner';
import { getBuildProcessInfo } from 'utils/buildProcess';
import withContentstackPreview from 'utils/livePreview';

import { THelpAndSupportPageData } from './models';

import './HelpAndSupportPage.scss';

/**
 * Private & public template
 */
export const HelpAndSupportPage: FC<THelpAndSupportPageData> = ({
  data: {
    pageData,
    footer,
    header,
    headerAccount,
    headerNavigationLoggedIn,
    footerNavigationLoggedIn,
  },
  pageContext,
}) => {
  getBuildProcessInfo(pageContext);
  const { seo, help_and_support_banner } = pageData;

  return (
    <Layout
      seo={seo}
      pageUid={pageContext.uid}
      color="blue"
      footer={footer}
      footerNavigationLoggedIn={footerNavigationLoggedIn}
      header={header}
      headerNavigationLoggedIn={headerNavigationLoggedIn}
      headerMode="transparent"
      headerAccount={headerAccount}
      privatePage={false}
      data-testid="HelpAndSupportPage"
    >
      <HelpAndSupportBanner data={help_and_support_banner} />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $locale: String) {
    pageData: contentstackFinishHelpAndSupportTemplate(id: { eq: $id }) {
      uid
      seo {
        ...SeoFragment
      }
      help_and_support_banner {
        ...HelpAndSupportBannerFragment
      }
    }
    footer: contentstackFinishFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    header: contentstackFinishHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    headerAccount: contentstackFinishHeaderAccount(publish_details: { locale: { eq: $locale } }) {
      ...HeaderAccountFragment
    }
    headerNavigationLoggedIn: contentstackFinishNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...HeaderNavigationLoggedInFragment
    }
    footerNavigationLoggedIn: contentstackFinishFooterNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...FooterNavigationLoggedInFragment
    }
    redirects: contentstackFinishGuard(publish_details: { locale: { eq: $locale } }) {
      ...AuthGuardFragment
    }
  }
`;

export default withContentstackPreview(
  HelpAndSupportPage,
  `${process.env.GATSBY_CONTENTSTACK_CONTENT_TYPES_PREFIX}_help_and_support_template`
);
